import React, { FC, useEffect, useState } from "react"
import Img from "gatsby-image"

import { useAllPokemon } from "../../hooks/useAllPokemon"
import { Pokemon, PokemonForm, PokemonNode } from "../../models/pokemon"
import { RocketMatch, RocketMatchType } from "../../models/rocketMatch"

import Box from "../box"
import Button from "../button"
import Card, { CardProps } from "../card"
import Heading from "../heading"
import Text from "../text"
import PokemonPreview from "../pokemon/pokemon-preview"

interface Props extends CardProps {
    team: RocketMatch;
    variant?: 'default'
}

// interface SelectedPokemon {
//     id: number;
//     mon: Pokemon;
// }

const RocketCard: FC<Props> = ({ team, variant = 'default', ...rest }) => {
    const pokemon = useAllPokemon()
    const [slots, setSlots] = useState<Array<Array<number>>>()

    // const [selectedPokemon, setSelectedPokemon] = useState<Record<string, Pokemon>>({})
    // const [selectedPokemon, setSelectedPokemon] = useState<Array<number | undefined>>([undefined, undefined, undefined])
    const [firstPokemon, setFirstPokemon] = useState<Pokemon | undefined>()
    const [secondPokemon, setSecondPokemon] = useState<Pokemon | undefined>()
    const [thirdPokemon, setThirdPokemon] = useState<Pokemon | undefined>()

    useEffect(() => {
        const { slots } = team
        const tempSlots = []
        tempSlots.push(slots.slotOne)
        tempSlots.push(slots.slotTwo)
        tempSlots.push(slots.slotThree)
        setSlots(tempSlots)
    }, [team])

    const onSelection = (index: number, mon: Pokemon) => {
        let setPokemon
        let id
        switch (index) {
            case 0:
                setPokemon = setFirstPokemon
                id = firstPokemon?.pokemonId
                break;
            case 1:
                setPokemon = setSecondPokemon
                id = secondPokemon?.pokemonId
                break;
            case 2:
                setPokemon = setThirdPokemon
                id = thirdPokemon?.pokemonId
                break;
        }
        if (!setPokemon) {
            return
        }
        console.log(id === mon.pokemonId)
        setPokemon(id === mon.pokemonId ? undefined : mon)
        // console.log(firstPokemon, secondPokemon, thirdPokemon)
        // const temp = Object.assign({}, selectedPokemon)
        // temp[index] = mon
        // setSelectedPokemon(temp)
        // console.log(selectedPokemon)
    }

    const hasCountersSelected = firstPokemon && secondPokemon && thirdPokemon

    const isSelectedPokemon = (slotIndex: number, selectedId: number) => {
        let selectedPokemon
        switch (slotIndex) {
            case 0:
                selectedPokemon = firstPokemon
                break;
            case 1:
                selectedPokemon = secondPokemon
                break;
            case 2:
                selectedPokemon = thirdPokemon
                break;
        }
        return selectedPokemon?.pokemonId === selectedId
    }

    return (
        <Card p={0} variant={variant} {...rest}>
            <Box position="relative">
                {team.image && <Img fluid={team.image.asset.fluid} />}
                <Box display="flex" alignItems="center" position="absolute" right={5} top="0" bottom="0" width="55%">
                    {team.prompt && <Text fontSize={[5, 7, 8, 9]} color="whiteAlways" m={0} lineHeight="1">{team.prompt}</Text>}
                    {team.matchType !== RocketMatchType.Grunt && <Heading as="h3" color="whiteAlways" fontSize={10} mb={0} textAlign="center" display="block" width="100%">{team.name}</Heading>}
                </Box>
            </Box>
            <Box p={3}>
                
                <Heading as="h4" fontSize={4} mb={1}>Possible Pokemon</Heading>
                <Text fontSize={3} color="grey.5">Select pokemon to get reccomendation</Text>

                <Box display="flex" justifyContent="space-between">
                    {slots && slots.map((slot, index) => (
                        <Box key={`${team.name}-slot-${index}`} p={2} flexBasis="32%">
                            <Heading as="h5" fontSize={4} mb={3}>Slot #{index + 1}</Heading>
                            <Box display="flex" flexDirection="column">
                                {slot.map((pokemonId, index2) => (
                                    <PokemonPreview
                                        pokemonRef={{pokemonId, form: PokemonForm.Shadow}}
                                        key={`${team.name}-slot-${index}-pokemon-${index2}`}
                                        borderColor={isSelectedPokemon(index, pokemonId) ? "primary" : "grey.0"}
                                        imageSize="50px"
                                        onClick={() => onSelection(index, pokemon[pokemonId - 1].node)}
                                        />
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
                {hasCountersSelected ?
                    <Box>
                        <Heading as="h4" fontSize={4} mt={5} mb={3}>Suggested Counters</Heading>

                    </Box>
                    :
                    <Button variant="secondary">Get General Reccomendation</Button>
                }
            </Box>
        </Card>
    )
}

export default RocketCard