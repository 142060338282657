import React, { FC } from "react"
import { graphql, PageProps } from "gatsby"

import SEO from "../components/seo"
import Card from "../components/card"
import Heading from "../components/heading"
import Link from "../components/link"
import Search from "../components/form/search"
import Box from "../components/box"
import RocketCard from "../components/rocket/rocket-card"
import { useAllPokemon } from "../hooks/useAllPokemon"
import { RocketMatch, RocketMatchNode, RocketMatchType } from "../models/rocketMatch"
import Wrapper from "../containers/wrapper"

interface Props extends PageProps {
    data: {
        allSanityRocketMatch: {
            edges: RocketMatchNode[]
        }
    }
}

const TeamRocket: FC<Props> = ({ data }) => {
    const pokemon = useAllPokemon()

    const { allSanityRocketMatch: { edges: rocketMatches } } = data

    const leaders: RocketMatch[] = rocketMatches.filter(match => match.node.matchType === RocketMatchType.Leader).map(({ node }) => node)
    const grunts: RocketMatch[] = rocketMatches.filter(match => match.node.matchType === RocketMatchType.Grunt).map(({ node }) => node)

    return (
        <Wrapper>
            <SEO title={`Pokemon Go Team Rocket`} />
            <Search placeholder="Search" mb={4} />
            <Heading as="h1" fontSize={7} mb={3}>Team Rocket</Heading>
            <Box display="flex" justifyContent="space-between" mx={-2} mb={5}>
                <Card display="flex" flex="1" mx={2}>
                    <Link display="block" width="100%" textAlign="center" py={4} to="" fontSize={2}>Overview</Link>
                </Card>
                <Card display="flex" flex="1" mx={2}>
                    <Link display="block" width="100%" textAlign="center" py={4} to="" fontSize={2}>Tips &amp; Tricks</Link>
                </Card>
            </Box>

            <Heading as="h2" fontSize={7}>Leaders</Heading>

            <Box display={{ xs: 'block', sm: 'grid' }} gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }} gridColumnGap={4}>
                {leaders.sort(({ name }, { name: name2 }) => name.localeCompare(name2)).map((leader, index) => (
                    <RocketCard key={`leader-${leader.name}-${index}`} team={leader} mb={4} />
                ))}
            </Box>

            <Heading as="h2" fontSize={7} mt={11}>Grunts</Heading>
            <Box display={{ xs: 'block', sm: 'grid' }} gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }} gridColumnGap={4}>
                {grunts.sort(({ name }, { name: name2 }) => name.localeCompare(name2)).map((leader, index) => (
                    <RocketCard key={`leader-${leader.name}-${index}`} team={leader} mb={4} />
                ))}
            </Box>

        </Wrapper>
    )
}

export const query = graphql`
  query {
    allSanityRocketMatch {
        edges {
            node {
                ...RocketMatchFields
            }
        }
    }
  }
`

export default TeamRocket
