import { graphql } from "gatsby";

export interface RocketMatchNode {
    node: RocketMatch
}

export interface RocketMatch {
    name: string;
    prompt: string;
    image: any;
    matchType: RocketMatchType;
    slots: {
        slotOne: number[],
        slotThree: number[],
        slotTwo: number[]
    };
}

export enum RocketMatchType {
    Giovanni = 'giovanni',
    Leader = 'leader',
    Grunt = 'grunt'
}

export const ROCKET_MATCH_CORE_FIELDS = graphql`
    fragment RocketMatchFields on SanityRocketMatch {
        name
        matchType
        prompt
        slots {
            slotOne
            slotThree
            slotTwo
        }
        image {
            asset {
                fluid(maxWidth: 700) {
                    ...GatsbySanityImageFluid
                }
            }
        }
    }
`
